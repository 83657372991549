<template>
<div class="input_box">
  <div class="input_left">
    <el-input
      v-model="textMsg"
      :autosize="{ maxRows: 2 }"
      type="textarea"
      resize="none"
      maxlength="300"
      placeholder="请输入消息.."
      show-word-limit
      :clearable="true"
      :focus="focusInput"
    >
    </el-input>
  </div>
  <div class="input_right" :class="isVoice?'hidden':''">
    <div class="btn" @click="sendText">发送</div>
  </div>
</div>
</template>

<script>
import { getStore } from '../../tools/storage'
export default {
  name: '',
  data () {
    return {
      textMsg: '', // 消息内容
      textMsgType: 'voice',
      isVoice: false,
      statustype: '',
      myicon: '' // 当前用户头像
    }
  },
  props: ['types'],
  components: {},
  methods: {
    sendText () { // 发送消息
      console.log(this.textMsg)
      console.log(this.$props.types)
      this.statustype = this.$props.types
      if (!this.textMsg) {
        return
      }
      if (this.textMsg && this.textMsgType === 'voice') { // 发送普通消息
        const s = this.textMsg
        console.log(s)
        const roomid = this.$store.state.currentid
        const productId = this.$store.state.productId || ''
        // console.log(getStore('usermessage'))
        const usermessage = JSON.parse(getStore('usermessage'))
        const mes = {
          code: '2',
          message: {
            avatar: this.myicon,
            content: s,
            fromid: usermessage.id,
            id: roomid,
            productId: productId,
            mine: true,
            role: usermessage.type,
            source: getStore('source'), // 来源 (会话列表：0，未下单：1，已下单：2，已收货：3，退款售后：4)"
            timestamp: new Date().getTime(),
            type: this.statustype,
            username: usermessage.realName || ''
          }
        }
        console.log(mes)
        this.$emit('pushmessage', mes)
        // this.$WebSocket.send(mes)
        this.textMsg = '' // 清空输入框
        // this.sendmessage = ''
      } else { // 发送表情消息

      }
    },
    focusInput () { // 获得焦点触发
      this.textMsgType = 'voice'
    }
  },
  mounted () {
    this.myicon = JSON.parse(getStore('usermessage')).icon || this.$aliUrl + 'c1.png'
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.input_box{
  width: 100%;
  display: flex;
  padding: 10px 5px;
  box-sizing: border-box;
  align-items: center;
  .input_left{
    flex: 1;
  }
  .input_right{
    margin-left: 20px;
    .btn{
      text-align: center;
      line-height: 30px;
      width: 50px;
      height: 30px;
      background: #3E1604;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
</style>
