<template>
<div>
  <el-button size="mini" @click="getsellerlist()">{{$t('m.reservation_varietiy_details.inquiry')}}</el-button>
  <div v-if="chatDialog">
    <el-dialog v-model="chatDialog"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose">
      <merchat-chat :productmes="this.productmesData"></merchat-chat>
    </el-dialog>
  </div>
</div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { setStore } from '../../tools/storage'
import { addchatGroup, sellerlist } from '../../common/InForMessage.js'
import MerchatChat from './merchatChat.vue'

export default {
  name: '',
  data () {
    return {
      // 聊天窗口信息
      productmesData: {}, // 传递到聊天窗口
      chatDialog: false,
      productInfo: {},
      merchantId: '',
      sellerlist: [] // 客服列表
    }
  },
  components: { MerchatChat },
  props: ['productData'],
  methods: {
    async goToMessage () { // 联系卖家
      // memberId 商家客服id merchantId //商家id
      console.log(this.$props.productData)
      // this.merchantId = this.$props
      this.merchantId = this.$props.productData.merchantId
      const data = {
        memberId: this.sellerlist[0].memberId,
        merchantId: this.merchantId
      }
      const res = await addchatGroup(data)
      // console.log(res)
      if (res.data.code === 200) {
        setStore('rommid', res.data.data.id)
        const product = {}
        product.merchantId = res.data.data.merchantId
        product.rommid = res.data.data.id
        this.$store.state.currentid = product.rommid
        this.$store.state.productId = this.$props.productData.productId
        product.productname = this.productInfo.name
        product.pic = this.productInfo.pic
        product.price = this.productInfo.price
        product.id = this.productInfo.id
        product.productId = this.$props.productData.productId
        product.companyName = this.$props.productData.companyName
        product.currencySymbol = this.productInfo.currencySymbol
        product.types = '0'// 单聊0
        product.source = '1'// 来源 (会话列表：0，未下单：1，已下单：2，已收货：3，退款售后：4)"
        const productmes = JSON.stringify(product)
        setStore('source', 1)// 来源 (会话列表：0，未下单：1，已下单：2，已收货：3，退款售后：4)"
        // uni.navigateTo({
        //   url: `/pages/information/merchatChat?productmes=${productmes}`
        // })
        this.productmesData = productmes
        // ElMessage.success({
        //   message: '发起成功'
        // })
        // console.log(productmes)
        this.chatDialog = true
      } else {
        ElMessage.error({
          message: '发起聊天失败..'
        })
      }
    },
    // 商家客服列表
    async getsellerlist () {
      const data = {
        pageNum: 1,
        pageSize: 100,
        productId: this.$props.productData.productId
      }
      const res = await sellerlist(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.sellerlist = res.data.data.records
        if (res.data.data.total === 0) {
          ElMessage.warning({
            message: '商家暂无客服在线..'
          })
          return
        }
        this.productInfo = this.$props.productData.productInfo
        this.goToMessage()
      }
    },
    handleClose () { // 关闭聊天
      this.chatDialog = false
    }
  },
  mounted () {

  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss'>
//@import ''; 引入公共css类
.el-dialog__body{
  padding-top: 15px;
}
</style>
