<template>
<!-- 聊天窗口 -->
<div class='chat_main'>
  <div class="chat_header">
    <div class="header_title">{{username}}</div>
    <div class="nav-right">
      <el-dropdown>
        <span>邀请平台客服</span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="(item,index) in merchantsLists" :key="index" divided
            @click="screen(item)">
              {{ item.customerServiceName }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- <span @click.stop="screen">邀请平台客服</span> -->
    </div>
  </div>
  <div class="chat_body">
    <el-scrollbar ref="myScrollbar" height="450px" @scroll="scrollFun(scrollLeft, scrollTop)">
      <div ref="innerRef">
        <div v-if="msgList.length == total" style="color:#999;text-align: center;line-height:40px;">没有更多了</div>
        <div v-if="msgList.length != total" v-loading="true"
        element-loading-background="rgba(229, 229, 229, 1)" style="margin:0 10px;text-align: center;height:40px;"></div>
        <div class="chat_msg row" v-for="(row,index) in msgList" :key="index" :id="'msg'+row.timestamp">
          <div>
            <!-- 自己发的消息 -->
            <div class="my" v-if="row.mine==true">
              <!-- 左消息 -->
              <div class="my_left">
                <!-- 文字消息 -->
                <div class="bubble"
                  v-if="!row.content.includes('voice') && !row.content.includes('img emoji')"
                  v-html="row.content">
                </div>
                <div class="bubbleimg" v-if="row.content.includes('<img emoji')" v-html="row.content"></div>
              </div>
              <!-- 右头像 -->
              <div class="my_right">
                <el-image style="width: 50px; height: 50px" :src="row.avatar" fit="cover" />
              </div>
            </div>
            <!-- 其他人发的消息 -->
            <div class="other" v-if="row.mine==false">
              <div class="other_left">
                <el-image style="width: 50px; height: 50px" :src="row.avatar" fit="fill" />
              </div>
              <div class="other_right">
                <div class="username">
                  <span class="name">{{row.username}}</span>
                </div>
                <!-- 文字消息 -->
                <div class="bubble"
                  v-if="!row.content.includes('voice') && !row.content.includes('img emoji')"
                  v-html="row.content">
                </div>
                <div class="bubbleimg" v-if="row.content.includes('<img emoji')" v-html="row.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
     <div class="chat_send">
        <div class="bottomshop" v-if="bottomshopShow">
          <div class="bottomshop_body">
            <el-image style="width: 80px; height: 80px" :src="productmesData.pic" fit="fill" />
            <div class="bottomshop-right">
              <div class="right-title">
                <div class="goodsname">{{productmesData.productname}}</div>
                <el-icon @click="closegoods"><Close /></el-icon>
              </div>
              <div class="goodsprice">
                <p>{{productmesData.currencySymbol}}{{productmesData.price}}</p>
                <!-- <div @click="send">发给商家</div> -->
                <el-button @click="send" color="#c6824e" round size="mini">发给商家</el-button>
              </div>
            </div>
          </div>
        </div>
        <chat-input @pushmessage="sendmessage" :types="this.types"></chat-input>
      </div>
  </div>
</div>
</template>

<script>
import throttle from '../../tools/throttle.js' // 节流函数
import { getStore } from '../../tools/storage'
import {
  getchatoldmes,
  merchantsList,
  addplatformCustomer,
  getchatUserList
} from '../../common/InForMessage.js'
import { ElMessage } from 'element-plus'
import ChatInput from './chatInput.vue'
export default {
  name: 'merchatChat',
  data () {
    return {
      username: '',
      productmesData: {},
      bottomshopShow: false, // 商品信息
      memberId: '', // 商家客服id
      types: '',
      tid: '', // 发送对象id
      productId: '', // 聊天商品id
      myuids: '',
      myicon: '',
      pageNum: 1,
      pageSize: 20,
      total: 0,

      platformstatus: '', // 是否已经有客服
      merchantsLists: [], // 客服列表
      msgList: [
        {
          avatar: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/userIcon/20220702/微信图片_20220702134846.png',
          cid: '553',
          content: '好的睡觉好的睡觉好的睡觉好的睡觉好的睡觉好的睡觉好的睡觉好的睡觉',
          fromid: 8,
          id: 103,
          mine: false,
          role: null,
          source: null,
          timestamp: 1656733295116,
          type: '0',
          username: '中咖商家'
        },
        {
          avatar: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/userIcon/20220702/微信图片_20220702134846.png',
          cid: '553',
          content: '好的睡觉好的睡觉好的睡觉好的睡觉好的睡觉好的睡觉好的睡觉好的睡觉',
          fromid: 8,
          id: 103,
          mine: false,
          role: null,
          source: null,
          timestamp: 1656733295116,
          type: '0',
          username: '中咖商家'
        },
        {
          avatar: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/userIcon/20220702/微信图片_20220702134846.png',
          cid: '553',
          content: '好的睡觉好',
          fromid: 8,
          id: 103,
          mine: false,
          role: null,
          source: null,
          timestamp: 1656733295116,
          type: '0',
          username: '中咖商家'
        },
        {
          avatar: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/userIcon/20220702/微信图片_20220702134846.png',
          cid: '553',
          content: '?????',
          fromid: 8,
          id: 103,
          mine: false,
          role: null,
          source: null,
          timestamp: 1656733295116,
          type: '0',
          username: '中咖商家'
        },
        {
          avatar: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/userIcon/20220702/微信图片_20220702134846.png',
          cid: '553',
          content: '好的睡觉好',
          fromid: 8,
          id: 103,
          mine: false,
          role: null,
          source: null,
          timestamp: 1656733295116,
          type: '0',
          username: '中咖商家'
        },
        {
          avatar: 'http://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/userIcon/20220629/b115f400ef2fbbc3465e8fc6de9cc0a.jpg',
          cid: '554',
          content: 'Hello，商家',
          fromid: 52,
          id: 103,
          mine: true,
          role: null,
          source: null,
          timestamp: 1656733304890,
          type: '0',
          username: 'e猫王x'
        }
      ] // 消息总体
    }
  },
  components: { ChatInput },
  props: ['productmes'],
  methods: {
    initData () { // 初始打开聊天窗口
      console.log(JSON.parse(this.$props.productmes))
      this.productmesData = JSON.parse(this.$props.productmes)
      this.bottomshopShow = true
      this.username = this.productmesData.companyName
      this.memberId = this.productmesData.memberId
      this.types = this.productmesData.types
      this.tid = this.productmesData.id
      this.productId = this.productmesData.productId
      this.$store.commit('currentRoomid', this.productmesData.rommid) // 存储聊天房间号
      this.myuids = JSON.parse(getStore('usermessage')).id
      this.myicon = JSON.parse(getStore('usermessage')).icon || this.$aliurl + 'c1.png'

      this.$WebSocket.acceptSocketMessage(data => {
        console.log('正常收到消息--当前会话')
        // console.log(data)
        const scrMdata = JSON.parse(data.data)
        const code = scrMdata.code
        if (code === '2') {
          // console.log(JSON.parse(data.data))
          const id = scrMdata.message.id
          // console.log(this.productmesData.rommid)
          console.log(scrMdata)
          // eslint-disable-next-line eqeqeq
          if (this.myuids == scrMdata.message.fromid && this.myicon == scrMdata.message.avatar) {
            scrMdata.message.mine = true
          }

          // eslint-disable-next-line eqeqeq
          if (id == this.productmesData.rommid) {
            this.screenMsg(scrMdata)
          } else {

          }
        }
      })
    },
    sendmessage (mes) { // 发消息
      // console.log(mes)
      this.$WebSocket.send(mes)
    },
    screenMsg (msgs) { // 接受消息
      const msg = msgs.message
      this.msgList.push(msg)
      this.total += 1
      this.chatHeghtBotm()
    },
    chatHeghtBotm () { // 回到最底部信息处
      this.$nextTick(() => {
        this.$refs.myScrollbar.setScrollTop(this.$refs.innerRef.clientHeight)
        // console.log(this.$refs.myScrollbar.wrap$) // 整体dom
        // console.log(this.$refs.innerRef) // 内层dom元素
        // console.log(this.$refs.innerRef.clientHeight) // 聊天消息dom高度
      })
    },
    scrollFun: throttle(function (scrollLeft, scrollTop) { // 消息滚动方法
      // console.log(this.$refs.myScrollbar)
      // eslint-disable-next-line eqeqeq
      if (this.$refs.myScrollbar.moveY == 0) {
      // eslint-disable-next-line eqeqeq
        if (this.msgList.length == this.total) {
          return
        }
        this.pageNum += 1
        this.getMsgList(this.pageNum)
      }
    }, 500),
    async getMsgList (num) {
      // console.log('历史记录----' + this.productmesData.rommid)
      const data = {
        pageNum: this.pageNum || num,
        pageSize: this.pageSize,
        chatType: 1,
        chatId: this.productmesData.rommid
      }
      const res = await getchatoldmes(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.total = res.data.data.total
        if (num) {
          this.msgList.unshift(...res.data.data.records.reverse())
        } else {
          this.msgList = res.data.data.records.reverse()
          this.chatHeghtBotm() // 到消息最底部
        }
      } else {
        ElMessage.warning({
          message: '查询失败'
        })
      }
    },
    async screen (item) { // 邀请平台客服
      // console.log(item)
      if (this.platformstatus) {
        ElMessage.warning({
          message: '平台客服已存在!'
        })
        return
      }
      const data = {
        chatId: this.productmesData.rommid,
        userId: item.memberId
      }
      const res = await addplatformCustomer(data)
      if (res.data.code === 200) {
        ElMessage.success({
          message: '平台客服加入成功!'
        })
        const mes = {
          code: '2',
          message: {
            avatar: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/logo/7.png' || this.platformimg,
            content: '您好，请问有什么需要帮助？',
            fromid: item.memberId,
            id: this.productmesData.rommid,
            productId: this.productId,
            mine: false,
            role: JSON.parse(getStore('usermessage')).type,
            source: getStore('source'), // 来源 (会话列表：0，未下单：1，已下单：2，已收货：3，退款售后：4)"
            timestamp: new Date().getTime(),
            type: this.types,
            username: '平台客服'
          }
        }
        // console.log(mes)
        this.sendmessage(mes)
        this.platformstatus = true
      }
    },
    // 平台客服列表
    async getMerchantsList () {
      const data = {
        pageNum: 1,
        pageSize: 100,
        merchantId: this.productmesData.merchantId
      }
      const res = await merchantsList(data)
      // console.log(res)
      this.merchantsLists = res.data.data.records
      this.merchantsLists.forEach(item => {
        item.selected = false
      })
      // console.log(this.merchantsLists)
    },
    async getchatUserLists () { // 查询是否已经包含平台客服
      const data = {
        chatId: this.productmesData.rommid
      }
      const res = await getchatUserList(data)
      // console.log(res)
      for (let i = 0; i < res.data.data.length; i++) {
        // eslint-disable-next-line eqeqeq
        if (res.data.data[i].name == '平台客服') {
          this.platformstatus = true
          break
        }
      }
    },
    send () { // 底部商品发送给商家
      const name = this.productmesData.productname
      const pic = this.productmesData.pic
      const price = this.productmesData.price
      const currencySymbol = this.productmesData.currencySymbol
      const id = this.productmesData.rommid
      console.log(id)
      const usermessage = JSON.parse(getStore('usermessage'))
      const con =
          `<view onClick="todetail()" style="min-height: 50px;background-color: #FFFFFF;border-radius: 5upx;
              display: flex;
              align-items: center;
              font-size: 15px;
              word-break: break-word;
              display: flex;
              flex-direction: column;
              justify-content:flex-start;
              align-items:flex-start;" class="product">
              <image mode="widthFix" style="width: 100%;max-height: 175px;" src="${pic}" alt=""></image>
              <view style="width:100%">${name}</view>
              <view style="width:100%">${currencySymbol}${price}</view>
            </view>`

      const mes = {
        code: '2',
        message: {
          avatar: this.myicon,
          content: con,
          fromid: usermessage.id,
          id: this.productmesData.rommid,
          productId: this.productId,
          mine: true,
          role: usermessage.type,
          source: getStore('source'), // 来源 (会话列表：0，未下单：1，已下单：2，已收货：3，退款售后：4)"
          timestamp: new Date().getTime(),
          type: this.types,
          username: usermessage.realName || ''
        }

      }

      this.sendmessage(mes)
      this.bottomshopShow = false
    },
    closegoods () { // 关闭底部商品
      this.bottomshopShow = false
    }
  },
  mounted () {
    this.initData() // 处理聊天窗口数据
    // this.chatHeghtBotm() // 回到最底部消息
    this.getMsgList() // 加载初始消息
    this.getMerchantsList() // 查询平台客服列表
    this.getchatUserLists() // 查询是否已经包含平台客服
  },
  setup (props, ctx) {
    // const datacop = getCurrentInstance()
    // datacop.data.username = props.productmes.companyName

    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.chat_main{
  width: 100%;
  // border: 1px solid #999;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  .chat_header{
    display: flex;
    align-items: center;
    width: 100%;
    height:35px;
    text-align: center;
    border-bottom: 1px solid #666;
    .header_title{
      font-size: 25px;
      flex: 1;
      color: #C6824E;
    }
    .nav-right{
      position: absolute;
      right: 32px;
      cursor: pointer;
    }
  }
  .chat_body{
    background: rgba(0, 0, 0, 0.1);
    .chat_msg{
      margin: 10px 0;
    }
    .row{
      padding: 0 10px;
      .my .my_left,.other .other_right{
        width: 100%;
        display: flex;
        .bubble{
          // box-sizing: border-box;
          max-width: 70%;
          min-height: 30px;
          border-radius: 8px;
          padding: 10px;
          font-size: 18px;
          word-break: break-word;
          display: flex;
          align-items: center;
          background: #fff;
          color: #333;
          .el-image{
            background: transparent;
            padding: 0;
            overflow: hidden;
            img{
              max-width: 200px;
              max-height: 200px;
            }
          }
        }
      }
      .my .my_right,.other .other_left{
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        /deep/ .el-image{
          .el-image__inner{
            width: 50px;
            height: 50px;
            border-radius: 8px;
          }
        }
      }
      .my{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .my_left{
          align-items: center;
          justify-content: flex-end;
          .bubble{
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 70%;
          }
        }
        .my_right{
          margin-left: 10px;
          display: flex;
          align-items: center;
        }
      }
      .other{
        width: 100%;
        display: flex;
        .other_left{
          margin-right: 10px;
        }
        .other_right{
          display: flex;
          flex-flow: wrap;
          .username{
            width: 100%;
            margin-bottom: 5px;
            height: 20px;
            font-size: 16px;
            color: #999;
            display: flex;
            .name{
              margin-right: 25px;
            }
          }
        }
      }
    }
    .chat_send{
      position: relative;
      .bottomshop{
        position: absolute;
        max-height: 100px;
        min-height: 100px;
        top: -110px;
        // padding: 0 20px;
        box-sizing: border-box;
        width: 100%;
        .bottomshop_body{
          margin: 0 20px;
          display: flex;
          padding: 10px;
          background: #fff;
          border-radius: 15px;
          .bottomshop-right{
            flex: 1;
            margin-left: 20px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            .right-title {
              width: 100%;
              display: flex;
              font-size: 18px;
              justify-content: space-between;
              align-items: center;
              color: #131313;
              overflow: hidden;
              .el-icon{
                cursor: pointer;
              }
            }
            .goodsprice {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              p{
                color: #FF5100;
                font-size: 18px;
                font-weight: 500;
              }
              /deep/.el-button{
                span{
                  color: #FFFFFF;
                }
              }
            }
          }
        }
      }
      width: 100%;
      background: #fff;
    }
  }
}
</style>
