/*
*节流函数
*/
// const timer = null
// function throttle (method, delay, duration) {
//   var that = this
//   const timer = delay
//   var begin = new Date().getTime()
//   return function () {
//     var context = that
//     var args = arguments
//     var current = new Date().getTime()
//     clearTimeout(timer)
//     if (current - begin >= duration) {
//       method.apply(context, args)
//       begin = current
//     } else {
//       console.log(delay)
//       that.timer = setTimeout(function () {
//         method.apply(context, args)
//       }, delay)
//     }
//   }
// }
function throttle (fn, delay = 200) {
  let timer = null
  return function () {
    if (timer) return
    timer = setTimeout(() => {
      fn.apply(this, arguments)
      timer = null
    }, delay)
  }
}
// 防抖
export function _debounce (fn, delay = 500) {
  var timer = null
  return function () {
    var _this = this
    var args = arguments
    if (timer) clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(_this, args)
    }, delay)
  }
}
export default throttle
